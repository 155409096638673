@font-face {
  font-family: "MontSerrat";
  src: local("MontSerrat"),
    url(../resources/Montserrat-VariableFont_wght.ttf) format("truetype");

  font-weight: bold;
}

body {
  background-color: #810001;
  text-align: center;
}

.image {
  width: 365px;
  height: 365px;
}

.imageDownload {
  width: 168px;
  height: 50px;
  margin: 50px;
}

.title {
  color: #ffffff;
  font-family: "MontSerrat";
  font-size: 50px;
  font-weight: 950;
  margin: 20px;
}

.subtitle {
  color: #ffffff;
  font-family: "MontSerrat";
  font-size: 20px;
  font-weight: 800;
  margin: 20px;
}

.footer {
  color: #ffffff;
  font-family: "MontSerrat";
  font-size: 15px;
  font-weight: 500;
  margin: 20px;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}