@font-face {
    font-family: "OpenSans";
    src: local("OpenSans"),
      url(../resources/OpenSans-VariableFont_wdth,wght.ttf) format("truetype");

  }

  .title {
    color: #000000;
    font-family: "OpenSans";
    font-size: 50px;
    font-weight: 950;
    padding-top: 50px;
  }

  .main {
    color: #000000;
    font-family: "OpenSans";
    text-align: center;
    margin: 50px;
  }
 